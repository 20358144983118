import Header from "../components/Header";
import "./Academicos.css";
import Imagens from "../components/Imagens";

import Academico1 from "../assets/artes/experimental1.png";
import Academico2 from "../assets/artes/experimental2.png";

export default function Academicos() {
  return (
    <>
      <Header cabecalho={"TRABALHOS ACADÊMICOS"} />
      <div className="conteudo-principal">
        <div className="publicidade-academica">
          <Imagens
            classe={"publicidade-academica-poster photo1"}
            imagem={Academico1}
            descritivo={
              "Criação de uma peça publicitária da Vodka Absolute com tema livre ( UNA 2008 )"
            }
            descricao={""}
          />

          <Imagens
            classe={"publicidade-academica-poster photo2"}
            imagem={Academico2}
            descritivo={
              "Projeto acadêmico para crição de um novo cartaz para o filme O homem que copiava ( UNA 2008 )"
            }
            descricao={""}
          />

          <div className="publicidade-academica-legenda leg1">
            Criação de uma peça publicitária da Vodka Absolute com tema livre (
            UNA 2008 )
          </div>
          <div className="publicidade-academica-legenda leg2">
            Projeto acadêmico para crição de um novo cartaz para o filme O homem
            que copiava ( UNA 2008 )
          </div>
        </div>
      </div>
    </>
  );
}
