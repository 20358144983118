import "./Temperaturas.css";
import { useState, useEffect } from "react";
import { BuscaClimaTempo } from "../api/weather.js";
import { ConvertCityLatLong } from "../api/geolocalizacao.js";

import {
  WiThermometer,
  WiHorizonAlt,
  WiHorizon,
  WiStrongWind,
  WiHumidity,
} from "react-icons/wi";

import {
  LiaTemperatureHighSolid,
  LiaTemperatureLowSolid,
} from "react-icons/lia";
import { LocalizacaoAtual } from "../api/geolocalizacaoAtual";

export default function Temperaturas() {
  const [coordenadas, setCoordenadas] = useState({});
  const [climaTempo, setClimaTempo] = useState({});
  const [geolocalizacaoErro, setGeolocalizacaoErro] = useState(false);

  //buscar geolocalização (latitude e longitude) ao passar o nome da cidade
  useEffect(() => {
    LocalizacaoAtual()
      .then((response) => {
        setCoordenadas(response);
      })
      .catch((error) => {
        console.log(error);
        setGeolocalizacaoErro(true); // Define a flag de erro como true
      });
  }, []);

  useEffect(() => {
    // Verifica se ocorreu um erro na geolocalização
    if (geolocalizacaoErro) {
      ConvertCityLatLong("Amadora", "Portugal")
        .then((response) => {
          setCoordenadas(response);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [geolocalizacaoErro]);

  //buscar informações do clima/tempo com base nas coordenadas buscadas na ConvertCityLatLong
  useEffect(() => {
    BuscaClimaTempo(coordenadas?.lat, coordenadas?.lon)
      .then((response) => {
        setClimaTempo(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [coordenadas]);

  useEffect(() => {
    console.log("lat/long", coordenadas);
    console.log("clima cidade", climaTempo);
  }, [coordenadas, climaTempo]);

  function convertTimeStampEmHoras(stamp) {
    const data = new Date(stamp * 1000); // Multiplicamos por 1000 para obter o valor em milissegundos

    const horas = data.getHours();
    const minutos =
      data.getMinutes() < 10 ? "0" + data.getMinutes() : data.getMinutes();

    return `${horas}h${minutos}`;
  }

  if (climaTempo.weather && climaTempo.weather.length > 0) {
    return (
      <div className="clima-tempo-box">
        <div className="linha-1">
          <div className="nome-localidade">
            {climaTempo.name}, {climaTempo.main.temp.toFixed(0)}ºC
          </div>
        </div>
        <div className="linha-2">
          <div
            className="icone-tempo"
            data-message={climaTempo.weather[0].description}
          >
            <img
              src={`https://openweathermap.org/img/wn/${climaTempo.weather[0].icon}@2x.png`}
            />
          </div>
          <div className="sensacao-termica">
            <div className="termometro-icon" data-message="Sensação Térmica">
              <WiThermometer className="icone-destaque" />
            </div>
            <div className="termometro-valor">
              {climaTempo.main.feels_like.toFixed(0)}ºC
            </div>
          </div>
        </div>
        <div className="linha-3">
          <div className="coluna-1">
            <div className="icone-info" data-message="Temperatura Máxima">
              <LiaTemperatureHighSolid className="icone-destaque" />
            </div>
            <div className="info-valor">
              {climaTempo.main.temp_max.toFixed(0)}ºC
            </div>
          </div>
          <div className="coluna-2">
            <div className="icone-info" data-message="Temperatura Mínima">
              <LiaTemperatureLowSolid className="icone-destaque" />
            </div>
            <div className="info-valor">
              {climaTempo.main.temp_min.toFixed(0)}ºC
            </div>
          </div>
          <div className="coluna-3">
            <div className="icone-info" data-message="Velocidade do Vento">
              <WiStrongWind className="icone-destaque" />
            </div>
            <div className="info-valor">
              {climaTempo.wind.speed.toFixed(1)} km/h
            </div>
          </div>
          <div className="coluna-1">
            <div className="icone-info" data-message="Humidade Relativa do Ar">
              <WiHumidity className="icone-destaque" />
            </div>
            <div className="info-valor">{climaTempo.main.humidity}%</div>
          </div>
          <div className="coluna-2">
            <div className="icone-info" data-message="Nascer do Sol">
              <WiHorizonAlt className="icone-destaque" />
            </div>
            <div className="info-valor">
              {convertTimeStampEmHoras(climaTempo.sys.sunrise)}
            </div>
          </div>
          <div className="coluna-3">
            <div className="icone-info" data-message="Pôr do Sol">
              <WiHorizon className="icone-destaque" />
            </div>
            <div className="info-valor">
              {convertTimeStampEmHoras(climaTempo.sys.sunset)}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}
