import "./Imagens.css";
import React, { useState, useCallback } from "react";

export default function Imagens({
  classe,
  imagem,
  descritivo,
  descricao,
  link,
}) {
  const [showPopup, setShowPopup] = useState(false);

  const abrirLink = useCallback(() => {
    if (link) {
      window.open(link, "_blank");
    }
  }, [link]);

  return (
    <div
      className={`imagem-componente ${classe}`}
      data-message={descritivo}
      onMouseEnter={() => setShowPopup(true)}
      onMouseLeave={() => setShowPopup(false)}
      onClick={abrirLink}
    >
      <img src={imagem} alt={descritivo} className="imagem-grid" />
      {showPopup && <div className="popup">{descricao}</div>}
    </div>
  );
}
