export function LocalizacaoAtual() {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        var latitude = position.coords.latitude;
        var longitude = position.coords.longitude;

        var coordenadas = {
          lat: latitude,
          lon: longitude,
        };

        resolve(coordenadas);
      });
    } else {
      reject(new Error("Geolocalização não é suportada pelo seu navegador"));
    }
  });
}
