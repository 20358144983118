import Header from "../components/Header";
import "./EbookCanada.css";
import { Link } from "react-router-dom";

import Capa from "../assets/ebook/capa5.jpg";
import Estado1 from "../assets/ebook/can-2.jpg";
import Estado2 from "../assets/ebook/can-3.jpg";
import Curiosidades from "../assets/ebook/can-5.jpg";
import CompreAgoraEUR from "../assets/ebook/compreagora-euro.png";
import CompreAgoraBLR from "../assets/ebook/compreagora-reais.png";
import Reais from "../assets/ebook/reais.png";
import Euros from "../assets/ebook/euro.png";
import Voltar from "../assets/diversos/voltar.png";

export default function Ebook() {
  return (
    <>
      <Header
        cabecalho={"A História de Todas as Bandeiras - Edição Especial: Canadá"}
      />

      <div className="conteudo-principal">
        <div className="paginas-desenvolvidas-can">
          <div className="paginas-desenvolvidas-can-texto">
            <b>
              E-book: A História de Todas as Bandeiras - Edição Especial: Canadá
            </b>
            <br /> Nesta edição especial, vamos fundo nas histórias por trás das
            bandeiras de cada província e território canadense. Apresentamos não
            só dados importantes sobre cada província, mas também curiosidades
            sobre seus selos e as bandeiras marcantes das capitais. A narrativa
            descomplicada revela a trajetória da criação desses símbolos
            oficiais, conectando o significado por trás de cada selo e bandeira.
            Além disso, exploramos os mapas provinciais de maneira simples,
            proporcionando uma visão completa. Não perca ainda fatos e
            curiosidades interessantes sobre várias províncias canadenses. Este
            livro convida você para uma jornada descontraída, desvendando a
            diversidade e história do país.
          </div>

          <div
            className="paginas-desenvolvidas-can-1"
            data-message="E-book - Capa"
          >
            <img src={Capa} alt="E-book capa" />
          </div>

          <div
            className="paginas-desenvolvidas-can-2"
            data-message="E-book - Colúmbia Britânica"
          >
            <img src={Estado1} alt="E-book Colúmbia Britânica" />
          </div>

          <div
            className="paginas-desenvolvidas-can-3"
            data-message="E-book - Nova Brunswick"
          >
            <img src={Estado2} alt="E-book Nova Brunswick" />
          </div>

          <div
            className="paginas-desenvolvidas-can-4"
            data-message="E-book - Curiosidades"
          >
            <img src={Curiosidades} alt="E-book Curiosidades" />
          </div>

          <div className="paginas-desenvolvidas-can-logoautores">
            <img src={Euros} />
            <a
              href="https://clubedeautores.pt/book/629297--A_Historia_de_Todas_as_Bandeiras"
              target="_blank"
            >
              <img
                alt="Compre aqui o livro 'A História de Todas as Bandeiras'"
                src={CompreAgoraEUR}
              />
            </a>
            <img src={Reais} />
            <a
              href="https://clubedeautores.com.br/book/629301--A_Historia_de_Todas_as_Bandeiras"
              target="_blank"
            >
              <img
                alt="Compre aqui o livro 'A História de Todas as Bandeiras'"
                src={CompreAgoraBLR}
              />
            </a>
            <Link to="/ebook">
              <img src={Voltar} alt="Voltar" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
