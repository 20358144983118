import React from "react";
import { Link } from "react-router-dom";
import "./Menu.css";
import $ from "jquery";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencil,
  faShield,
  faCalendarDays,
  faBuildingColumns,
  faBezierCurve,
  faLaptopCode,
  faNetworkWired,
  faBook,
} from "@fortawesome/free-solid-svg-icons";

export default function Menu() {
  $(document).ready(function () {
    $(".menu-button").on("click", function () {
      $(".menu-bar").toggleClass("open");
    });

    $(document).on("mouseup", function (event) {
      if (!$(event.target).closest(".menu-bar, .menu-button").length) {
        $(".menu-bar").removeClass("open");
      }
    });

    $(".menu-bar").on("mouseleave", function () {
      $(".menu-bar").removeClass("open");
    });

    $(".menu-bar .menu-item").on("click", function () {
      $(".menu-bar").removeClass("open");
    });
  });

  return (
    <div className="menu-geral">
      <ul className="menu">
        <li title="home">
          <a href="#" class="menu-button home">
            menu
          </a>
        </li>

        <li title="Home Page">
          <Link to="/" className="homepage">
            Home
          </Link>
        </li>

        <li title="About">
          <Link to="/profile" className="about">
            Home
          </Link>
        </li>

        <li title="Contato">
          <Link to="/contato" className="contact">
            Contato
          </Link>
        </li>
      </ul>

      <ul className="menu-bar">
        <li>
          <a href="#" className="menu-button">
            Trabalhos
          </a>
        </li>

        <li>
          <Link to="/academico" className="menu-item">
            <FontAwesomeIcon icon={faBuildingColumns} /> Acadêmicos
          </Link>
        </li>

        <li>
          <Link to="/agenda" className="menu-item">
            <FontAwesomeIcon icon={faCalendarDays} /> Agendas
          </Link>
        </li>

        <li>
          <Link to="/desenholivre" className="menu-item">
            <FontAwesomeIcon icon={faPencil} /> Desenho Livre
          </Link>
        </li>

        <li>
          <Link to="/logomarca" className="menu-item">
            <FontAwesomeIcon icon={faShield} /> Logomarcas
          </Link>
        </li>

        <li>
          <Link to="/software" className="menu-item">
            <FontAwesomeIcon icon={faLaptopCode} /> Softwares
          </Link>
        </li>

        <li>
          <Link to="/sites" className="menu-item">
            <FontAwesomeIcon icon={faNetworkWired} /> Sites
          </Link>
        </li>

        <li>
          <Link to="/vetdesenho" className="menu-item">
            <FontAwesomeIcon icon={faBezierCurve} /> Vetores
          </Link>
        </li>

        <li>
          <Link to="/ebook" className="menu-item">
            <FontAwesomeIcon icon={faBook} /> E-books
          </Link>
        </li>
      </ul>
    </div>
  );
}
