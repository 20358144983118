import "./Contato.css";
import SocialMedia from "../components/SocialMedia";
import ptflag from "../assets/diversos/pt-flag.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import qrcode from "../assets/diversos/qrcode.png";
import Header from "../components/Header";

export default function Contato() {
  return (
    <>
      <Header cabecalho={"CONTATO"} />
      <div className="conteudo-principal">
        <div className="conteudo-contato">
          <div className="card-qrcode">
            <span>
              <img src={qrcode} alt="QrCode" className="qrcode" />
            </span>
          </div>
          <div className="conteudo-contato-detalhes">
            <span className="telemovel-contato">
              <FontAwesomeIcon icon={faPhone} />
              <img src={ptflag} alt="" className="flag-contact" />
              <p className="numero-telemovel"> 933 651 251 </p>
            </span>
            <hr />
            <span className="email-contato">
              <FontAwesomeIcon icon={faEnvelope} />
              <p className="numero-telemovel"> frederico@zanitti.com.br </p>
            </span>
            <hr />
            <SocialMedia />
            <hr />
          </div>
        </div>
      </div>
    </>
  );
}
