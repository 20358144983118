import Header from "../components/Header";
import "./VetorizacaoDesenho.css";
import { useState, useEffect } from "react";

import CapAm_Des from "../assets/desenhos/ca_f.png";
import CapAm_Vet from "../assets/desenhos/ca_v.png";
import Pd_Des from "../assets/desenhos/Pd_Des.png";
import Pd_Vet from "../assets/desenhos/Pd_Vet.png";

export default function VetorizacaoDesenho() {
  const [openVet, setOpenVet] = useState(false);
  const [openVetItem2, setOpenVetItem2] = useState(false);

  const openVetores = () => {
    setOpenVet(true);
  };

  const openVetoresItem2 = () => {
    setOpenVetItem2(true);
  };

  const mudaSrc = (alterar) => {
    const imgVet = document.getElementById("imagem-vetorizada");
    alterar ? (imgVet.src = CapAm_Vet) : (imgVet.src = CapAm_Des);
  };

  const mudaSrcItem2 = (alterar) => {
    const imgVet = document.getElementById("imagem-vetorizada_item2");
    alterar ? (imgVet.src = Pd_Vet) : (imgVet.src = Pd_Des);
  };

  useEffect(() => {
    function handleKeyPress(event) {
      if (event.keyCode === 27) {
        // Código da tecla ESC
        setOpenVet(false);
      }
    }

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <>
      <Header cabecalho={"VETORIZAÇÃO DE DESENHOS"} />
      <div className="conteudo-principal">
        <div className="vetorizacao-desenhos">
          <div className="vetorizacao-desenhos-item vd-item1">
            <img
              src={CapAm_Des}
              alt="Capitão América - Desenho com lápis nº 2"
            />
          </div>
          <div className="vetorizacao-desenhos-item vd-item2">
            <img
              src={CapAm_Vet}
              alt="Capitão América - Vetorizado no CorelDraw"
            />
          </div>

          <div className="vd-dois-itens" onClick={() => openVetores()}>
            Clique aqui para vê-los sobrepostos
          </div>

          <div className="vetorizacao-desenhos-item vd-item1">
            <img src={Pd_Des} alt="Pato Donald - Desenho com lápis nº 2" />
          </div>

          <div className="vetorizacao-desenhos-item vd-item2">
            <img src={Pd_Vet} alt="Pato Donald - Vetorizado no CorelDraw" />
          </div>

          <div
            className="vd-dois-itens_item2"
            onClick={() => openVetoresItem2()}
          >
            Clique aqui para vê-los sobrepostos
          </div>

          {openVet && (
            <div className="overlay">
              <div className="abrir-imagem-vetorizada">
                <img
                  id="imagem-vetorizada"
                  src={CapAm_Des}
                  alt="Capitão América: Desenho com lápis nº 2 / Vetorizado CorelDraw"
                  onClick={() => setOpenVet(false)}
                  onMouseOver={() => mudaSrc(true)}
                  onMouseOut={() => mudaSrc(false)}
                />
              </div>
            </div>
          )}

          {openVetItem2 && (
            <div className="overlay">
              <div className="abrir-imagem-vetorizada">
                <img
                  id="imagem-vetorizada_item2"
                  src={Pd_Des}
                  alt="Pato Donald: Desenho com lápis nº 2 / Vetorizado CorelDraw"
                  onClick={() => setOpenVetItem2(false)}
                  onMouseOver={() => mudaSrcItem2(true)}
                  onMouseOut={() => mudaSrcItem2(false)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
