import "./Profile.css";
import fred from "../assets/diversos/fred.png";
import Header from "../components/Header";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faCake,
  faLocationDot,
  faGamepad,
  faGraduationCap,
  faUserGraduate,
  faAt,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

function calculcarIdade() {
  let dataNascimento = "1976-12-19";
  const dataAtual = new Date();
  const anoAtual = dataAtual.getFullYear();
  const mesAtual = dataAtual.getMonth() + 1; // Os meses são indexados de 0 a 11
  const diaAtual = dataAtual.getDate();

  const partesData = dataNascimento.split("-");
  const anoNascimento = parseInt(partesData[0]);
  const mesNascimento = parseInt(partesData[1]);
  const diaNascimento = parseInt(partesData[2]);

  let idade = anoAtual - anoNascimento;

  // Verificar se o mês de nascimento é maior que o mês atual
  if (mesNascimento > mesAtual) {
    idade--;
  } else if (mesNascimento === mesAtual) {
    // Verificar se o mês é o mesmo, mas o dia de nascimento é maior
    if (diaNascimento > diaAtual) {
      idade--;
    }
  }

  return idade;
}

export default function Profile() {
  return (
    <>
      <Header cabecalho={"ABOUT ME"} />
      <div className="conteudo-principal">
        <div className="profile-geral">
          <div className="profile-foto">
            <img src={fred} className='photo-profile' alt="Frederico Zanitti Silva" />
          </div>
          <div className="profile-titulo">
            <span>
              Sou Frederico Zanitti, analista e desenvoledor de sistemas
            </span>
          </div>
          <div className="profile-descricao">
            <span>
              Meu nome é Frederico Zanitti Silva, tenho me dedicado à area de
              desenvolvimento e programação há mais de 20 anos! Trabalho como
              programador e sou formado em Sistema da Informação. Essa
              experiência me ajudou a adiquirir muitos conhecimentos sólidos e
              desenvolvimento de novas habilidades e intereses. Após todos esses
              mais de 20 anos como programador Delphi, agora estou aprimorando
              meu know-how em React/JavaScript.
            </span>
          </div>
          <div className="profile-id1">
            <FontAwesomeIcon icon={faCake} className="icone-id" />{" "}
            <p>Aniversário</p>
          </div>
          <div className="profile-id2">19 de dezembro</div>
          <div className="profile-id3">
            <FontAwesomeIcon icon={faCalendar} className="icone-id" />{" "}
            <p>Idade</p>
          </div>
          <div className="profile-id4">{calculcarIdade()}</div>

          <div className="profile-id1">
            <FontAwesomeIcon icon={faLocationDot} className="icone-id" />{" "}
            <p>Local</p>
          </div>
          <div className="profile-id2">Lisboa, Portugal</div>
          <div className="profile-id3">
            <FontAwesomeIcon icon={faGamepad} className="icone-id" />{" "}
            <p>Interesses</p>
          </div>
          <div className="profile-id4">
            Futebol, música, livros e programação
          </div>

          <div className="profile-id1">
            <FontAwesomeIcon icon={faUserGraduate} className="icone-id" />{" "}
            <p>Formação</p>
          </div>
          <div className="profile-id2">Sistema da Informação</div>
          <div className="profile-id3">
            <FontAwesomeIcon icon={faGraduationCap} className="icone-id" />{" "}
            <p>Título</p>
          </div>
          <div className="profile-id4">Bacharel</div>

          <div className="profile-id1">
            <FontAwesomeIcon icon={faAt} className="icone-id" /> <p>E-mail</p>
          </div>
          <div className="profile-id2">frederico@zanitti.com.br</div>
          <div className="profile-id3">
            <FontAwesomeIcon icon={faPhone} className="icone-id" />{" "}
            <p>Telefone</p>
          </div>
          <div className="profile-id4">+351 933 651 251</div>
        </div>
      </div>
    </>
  );
}
