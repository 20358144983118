import "./Logomarcas.css";
import Header from "../components/Header";
import FiveArts from "../assets/logomarcas/fivearts.png";
import Barbante from "../assets/logomarcas/barbante.png";
import Canecas from "../assets/logomarcas/canecas.png";
import Conectar from "../assets/logomarcas/conectar.png";
import DoisIrmaos from "../assets/logomarcas/doisirmaos.png";
import Igreja from "../assets/logomarcas/igreja.png";
import Assis from "../assets/logomarcas/logoassis.png";
import Condominio from "../assets/logomarcas/logocond.png";
import MestreCuca from "../assets/logomarcas/mestrecuca.png";
import MMFlores from "../assets/logomarcas/mmflores.png";
import Paloma from "../assets/logomarcas/paloma.png";
import Softclean from "../assets/logomarcas/softclean.png";
import SudesteGesso from "../assets/logomarcas/sudeste.png";

export default function Logomarcas() {
  return (
    <>
      <Header cabecalho={"LOGOMARCAS DESENVOLVIDAS"} />
      <div className="conteudo-principal">
        <div className="logomarcas-desenvolvidas">
          <div
            className="logomarcas-desenvolvidas-1"
            data-message="5 Arts | Belo Hoizonte/MG"
          >
            <img src={FiveArts} alt="5 Arts | Belo Hoizonte/MG" />
          </div>
          <div
            className="logomarcas-desenvolvidas-2"
            data-message="Arte com Barbante, confecção de tapetes | São João del Rei/MG"
          >
            <img
              src={Barbante}
              alt="Arte com Barbante, confecção de tapetes | São João del Rei/MG"
            />
          </div>
          <div
            className="logomarcas-desenvolvidas-3"
            data-message="+Canecas Personalizadas | Belo Hoizonte/MG"
          >
            <img
              src={Canecas}
              alt="+Canecas Personalizadas | Belo Hoizonte/MG"
            />
          </div>
          <div
            className="logomarcas-desenvolvidas-4"
            data-message="Conectar Elétrica Serviços e Automação Industrial | Belo Hoizonte/MG"
          >
            <img
              src={Conectar}
              alt="Conectar Elétrica Serviços e Automação Industrial | Belo Hoizonte/MG"
            />
          </div>
          <div
            className="logomarcas-desenvolvidas-5"
            data-message="Pesque Pague e Restaurante Dois Irmãos | São João del Rei/MG"
          >
            <img
              src={DoisIrmaos}
              alt="Pesque Pague e Restaurante Dois Irmãos | São João del Rei/MG"
            />
          </div>

          <div
            className="logomarcas-desenvolvidas-1"
            data-message="Igreja Pentecostal União e Graça | Belo Hoizonte/MG"
          >
            <img
              src={Igreja}
              alt="Igreja Pentecostal União e Graça | Belo Hoizonte/MG"
            />
          </div>
          <div
            className="logomarcas-desenvolvidas-2"
            data-message="Assis & Assis Ltda, logomarca comemorativa | Belo Hoizonte/MG"
          >
            <img
              src={Assis}
              alt="Assis & Assis Ltda, logomarca comemorativa | Belo Hoizonte/MG"
            />
          </div>
          <div
            className="logomarcas-desenvolvidas-3"
            data-message="Condomínio Residencial Filadéfia | Belo Horizonte/MG"
          >
            <img
              src={Condominio}
              alt="Condomínio Residencial Filadéfia | Belo Horizonte/MG"
            />
          </div>
          <div
            className="logomarcas-desenvolvidas-4"
            data-message="Software de Gestão Culinária"
          >
            <img src={MestreCuca} alt="Software de Gestão Culinária" />
          </div>
          <div
            className="logomarcas-desenvolvidas-5"
            data-message="M&M Flores | São João del Rei/MG"
          >
            <img src={MMFlores} alt="M&M Flores | São João del Rei/MG" />
          </div>

          <div
            className="logomarcas-desenvolvidas-1"
            data-message="Paloma Gil Fotografia | Belo Horizonte/MG"
          >
            <img src={Paloma} alt="Paloma Gil Fotografia | Belo Horizonte/MG" />
          </div>
          <div
            className="logomarcas-desenvolvidas-2"
            data-message="Logo de produto, trabalho acadêmico"
          >
            <img src={Softclean} alt="Logo de produto, trabalho acadêmico" />
          </div>
          <div
            className="logomarcas-desenvolvidas-3"
            data-message="Sudeste Gesso | São João del Rei/MG"
          >
            <img src={SudesteGesso} alt="Logo de produto, trabalho acadêmico" />
          </div>
        </div>
      </div>
    </>
  );
}
