import Header from "../components/Header";
import "./Ebook.css";
import Imagens from "../components/Imagens";
import { Link } from "react-router-dom";

import Capa1 from "../assets/ebook/capa1.jpg";
import Capa2 from "../assets/ebook/capa3.jpg";
import Capa3 from "../assets/ebook/capa4.jpg";
import Capa4 from "../assets/ebook/capa5.jpg";
import Capa5 from "../assets/ebook/capa6.jpg";

export default function Academicos() {
  return (
    <>
      <Header cabecalho={"E-BOOKS"} />
      <div className="conteudo-principal">
        <div className="ebook">
          <Link to="/ebookMundo">
            <Imagens
              classe={"photo1"}
              imagem={Capa1}
              descritivo={"A História de Todas as Bandeiras do Mundo"}
              descricao={""}
            />
          </Link>

          <Link to="/ebookBrasil">
            <Imagens
              classe={"photo2"}
              imagem={Capa2}
              descritivo={
                "A História de Todas as Bandeiras - Edição Especial: Brasil"
              }
              descricao={""}
            />
          </Link>

          <Link to="/ebookEua">
            <Imagens
              classe={"photo3"}
              imagem={Capa3}
              descritivo={
                "A História de Todas as Bandeiras - Edição Especial: Estados Unidos"
              }
              descricao={""}
            />
          </Link>

          <Link to="/ebookCanada">
            <Imagens
              classe={"photo4"}
              imagem={Capa4}
              descritivo={
                "A História de Todas as Bandeiras - Edição Especial: Canadá"
              }
              descricao={""}
            />
          </Link>

          <Link to="/ebookPortugal">
            <Imagens
              classe={"photo5"}
              imagem={Capa5}
              descritivo={
                "A História de Todas as Bandeiras - Edição Especial: Portugal"
              }
              descricao={""}
            />
          </Link>          

          <div className="ebook-legenda leg1">
            A História de Todas as Bandeiras do Mundo
          </div>
          <div className="ebook-legenda leg2">
            A História de Todas as Bandeiras - Edição Especial: Brasil
          </div>
          <div className="ebook-legenda leg3">
            A História de Todas as Bandeiras - Edição Especial: Estados Unidos
          </div>
          <div className="ebook-legenda leg4">
            A História de Todas as Bandeiras - Edição Especial: Canadá
          </div>
          <div className="ebook-legenda leg5">
            A História de Todas as Bandeiras - Edição Especial: Portugal
          </div>          
        </div>
      </div>
    </>
  );
}
