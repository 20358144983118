import "./Header.css";

export default function Header({ cabecalho }) {
  return (
    <div>
      <span className="titulo-header" id="titulo-header">
        {cabecalho}
      </span>
    </div>
  );
}
