import "./DesenhoLivre.css";
import Header from "../components/Header";
import React, { useState, useEffect } from "react";

/* Miniaturas */
import Clubes1_min from "../assets/desenhos/adcmm_a.png";
import Herois1_min from "../assets/desenhos/aquaman_a.png";
import Herois2_min from "../assets/desenhos/capitaoamerica_a.png";
import Herois3_min from "../assets/desenhos/capitaoamerica2_a.png";
import Herois4_min from "../assets/desenhos/homemaranha_a.png";
import Herois5_min from "../assets/desenhos/homemaranha2_a.png";
import Herois6_min from "../assets/desenhos/supergirl_a.png";
import Herois7_min from "../assets/desenhos/superman_a.png";
import Herois8_min from "../assets/desenhos/supermanlouislane_a.png";
import Carros1_min from "../assets/desenhos/mitsubishi_a.png";
import Carros2_min from "../assets/desenhos/peugeot_a.png";
import Carros3_min from "../assets/desenhos/puma_a.png";
import Carros4_min from "../assets/desenhos/moto_a.png";
import Animad1_min from "../assets/desenhos/patodonald1_a.png";
import Animad2_min from "../assets/desenhos/patodonald2_a.png";
/* Desenhos completos */
import Clubes1_int from "../assets/desenhos/adcmm.png";
import Herois1_int from "../assets/desenhos/aquaman.png";
import Herois2_int from "../assets/desenhos/capitaoamerica.png";
import Herois3_int from "../assets/desenhos/capitaoamerica2.png";
import Herois4_int from "../assets/desenhos/homemaranha.png";
import Herois5_int from "../assets/desenhos/homemaranha2.png";
import Herois6_int from "../assets/desenhos/supergirl.png";
import Herois7_int from "../assets/desenhos/superman.png";
import Herois8_int from "../assets/desenhos/supermanlouislane.png";
import Carros1_int from "../assets/desenhos/mitsubishi.png";
import Carros2_int from "../assets/desenhos/peugeot.png";
import Carros3_int from "../assets/desenhos/puma.png";
import Carros4_int from "../assets/desenhos/moto.png";
import Animad1_int from "../assets/desenhos/patodonald1.png";
import Animad2_int from "../assets/desenhos/patodonald2.png";

export default function DesenhoLivre() {
  const [showPopUp, setShowPopUp] = useState(0);
  const [caption, setCaption] = useState("");

  function clickOnImage(cap, popu) {
    setCaption(cap);
    setShowPopUp(popu);
  }

  useEffect(() => {
    function handleKeyPress(event) {
      if (event.keyCode === 27) {
        // Código da tecla ESC
        setShowPopUp(false);
      }
    }

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <>
      <Header cabecalho={"DESENHOS LIVRES"} />
      <div className="conteudo-principal">
        <div className="desenhos-livres">
          <div className="desenhos-livres-titulo">Clubes e Associações</div>
          <div className="desenhos-livres-item unico-item">
            <div className="imagem-miniatura" data-message={caption}>
              <img
                onClick={() =>
                  clickOnImage(
                    "Associação Desportiva Classista Mineira de Metais ( Ano: 1995 )",
                    1
                  )
                }
                src={Clubes1_min}
                alt={caption}
              />
            </div>
            {showPopUp === 1 && (
              <div className="overlay">
                <div className="imagem-after-click" data-message={caption}>
                  <img
                    className="imagem-popup"
                    src={Clubes1_int}
                    alt={caption}
                    onClick={() => clickOnImage("", false)}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="desenhos-livres-titulo">Super Hérois</div>

          <div className="desenhos-livres-item dl-item1">
            <div className="imagem-miniatura" data-message={caption}>
              <img
                onClick={() => clickOnImage("Aquaman ( Ano: 2000 )", 2)}
                src={Herois1_min}
                alt={caption}
              />
            </div>
            {showPopUp === 2 && (
              <div className="overlay">
                <div className="imagem-after-click" data-message={caption}>
                  <img
                    className="imagem-popup"
                    src={Herois1_int}
                    alt={caption}
                    onClick={() => clickOnImage("", false)}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="desenhos-livres-item dl-item2">
            <div className="imagem-miniatura" data-message={caption}>
              <img
                onClick={() => clickOnImage("Capitão América ( Ano: 2000 )", 3)}
                src={Herois2_min}
                alt={caption}
              />
            </div>
            {showPopUp === 3 && (
              <div className="overlay">
                <div className="imagem-after-click" data-message={caption}>
                  <img
                    className="imagem-popup"
                    src={Herois2_int}
                    alt={caption}
                    onClick={() => clickOnImage("", false)}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="desenhos-livres-item dl-item3">
            <div className="imagem-miniatura" data-message={caption}>
              <img
                onClick={() => clickOnImage("Capitão América ( Ano: 1999 )", 4)}
                src={Herois3_min}
                alt={caption}
              />
            </div>
            {showPopUp === 4 && (
              <div className="overlay">
                <div className="imagem-after-click" data-message={caption}>
                  <img
                    className="imagem-popup"
                    src={Herois3_int}
                    alt={caption}
                    onClick={() => clickOnImage("", false)}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="desenhos-livres-item dl-item4">
            <div className="imagem-miniatura" data-message={caption}>
              <img
                onClick={() => clickOnImage("Homem-Aranha ( Ano: 2000 )", 5)}
                src={Herois4_min}
                alt={caption}
              />
            </div>
            {showPopUp === 5 && (
              <div className="overlay">
                <div className="imagem-after-click" data-message={caption}>
                  <img
                    className="imagem-popup"
                    src={Herois4_int}
                    alt={caption}
                    onClick={() => clickOnImage("", false)}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="desenhos-livres-item dl-item1">
            <div className="imagem-miniatura" data-message={caption}>
              <img
                onClick={() => clickOnImage("Homem-Aranha ( Ano: 1996 )", 6)}
                src={Herois5_min}
                alt={caption}
              />
            </div>
            {showPopUp === 6 && (
              <div className="overlay">
                <div className="imagem-after-click" data-message={caption}>
                  <img
                    className="imagem-popup"
                    src={Herois5_int}
                    alt={caption}
                    onClick={() => clickOnImage("", false)}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="desenhos-livres-item dl-item2">
            <div className="imagem-miniatura" data-message={caption}>
              <img
                onClick={() => clickOnImage("Supergirl ( Ano: 2000 )", 7)}
                src={Herois6_min}
                alt={caption}
              />
            </div>
            {showPopUp === 7 && (
              <div className="overlay">
                <div className="imagem-after-click" data-message={caption}>
                  <img
                    className="imagem-popup"
                    src={Herois6_int}
                    alt={caption}
                    onClick={() => clickOnImage("", false)}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="desenhos-livres-item dl-item3">
            <div className="imagem-miniatura" data-message={caption}>
              <img
                onClick={() => clickOnImage("Superman ( Ano: 2000 )", 8)}
                src={Herois7_min}
                alt={caption}
              />
            </div>
            {showPopUp === 8 && (
              <div className="overlay">
                <div className="imagem-after-click" data-message={caption}>
                  <img
                    className="imagem-popup"
                    src={Herois7_int}
                    alt={caption}
                    onClick={() => clickOnImage("", false)}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="desenhos-livres-item dl-item4">
            <div className="imagem-miniatura" data-message={caption}>
              <img
                onClick={() =>
                  clickOnImage(
                    "O beijo de Superman & Louis Lane ( Ano: 2000 )",
                    9
                  )
                }
                src={Herois8_min}
                alt={caption}
              />
            </div>
            {showPopUp === 9 && (
              <div className="overlay">
                <div className="imagem-after-click" data-message={caption}>
                  <img
                    className="imagem-popup"
                    src={Herois8_int}
                    alt={caption}
                    onClick={() => clickOnImage("", false)}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="desenhos-livres-titulo">Veículos</div>
          <div className="desenhos-livres-item dl-item1">
            <div className="imagem-miniatura" data-message={caption}>
              <img
                onClick={() => clickOnImage("Mitisubishi ( Ano: 1995 )", 10)}
                src={Carros1_min}
                alt={caption}
              />
            </div>
            {showPopUp === 10 && (
              <div className="overlay">
                <div className="imagem-after-click" data-message={caption}>
                  <img
                    className="imagem-popup"
                    src={Carros1_int}
                    alt={caption}
                    onClick={() => clickOnImage("", false)}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="desenhos-livres-item dl-item2">
            <div className="imagem-miniatura" data-message={caption}>
              <img
                onClick={() => clickOnImage("Peugeot 206 ( Ano: 1994 )", 11)}
                src={Carros2_min}
                alt={caption}
              />
            </div>
            {showPopUp === 11 && (
              <div className="overlay">
                <div className="imagem-after-click" data-message={caption}>
                  <img
                    className="imagem-popup"
                    src={Carros2_int}
                    alt={caption}
                    onClick={() => clickOnImage("", false)}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="desenhos-livres-item dl-item3">
            <div className="imagem-miniatura" data-message={caption}>
              <img
                onClick={() => clickOnImage("Puma GT ( Ano: 1993 )", 12)}
                src={Carros3_min}
                alt={caption}
              />
            </div>
            {showPopUp === 12 && (
              <div className="overlay">
                <div className="imagem-after-click" data-message={caption}>
                  <img
                    className="imagem-popup"
                    src={Carros3_int}
                    alt={caption}
                    onClick={() => clickOnImage("", false)}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="desenhos-livres-item dl-item4">
            <div className="imagem-miniatura" data-message={caption}>
              <img
                onClick={() =>
                  clickOnImage(
                    "Moto Yamaha - Alexandre Barros ( Ano: 1996 )",
                    13
                  )
                }
                src={Carros4_min}
                alt={caption}
              />
            </div>
            {showPopUp === 13 && (
              <div className="overlay">
                <div className="imagem-after-click" data-message={caption}>
                  <img
                    className="imagem-popup"
                    src={Carros4_int}
                    alt={caption}
                    onClick={() => clickOnImage("", false)}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="desenhos-livres-titulo">Desenho Animado</div>
          <div className="desenhos-livres-item dois-itens-item1">
            <div className="imagem-miniatura" data-message={caption}>
              <img
                onClick={() => clickOnImage("Pato Donald ( Ano: 1994 )", 14)}
                src={Animad1_min}
                alt={caption}
              />
            </div>
            {showPopUp === 14 && (
              <div className="overlay">
                <div className="imagem-after-click" data-message={caption}>
                  <img
                    className="imagem-popup"
                    src={Animad1_int}
                    alt={caption}
                    onClick={() => clickOnImage("", false)}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="desenhos-livres-item dois-itens-item2">
            <div className="imagem-miniatura" data-message={caption}>
              <img
                onClick={() => clickOnImage("Pato Donald ( Ano: 1993 )", 15)}
                src={Animad2_min}
                alt={caption}
              />
            </div>
            {showPopUp === 15 && (
              <div className="overlay">
                <div className="imagem-after-click" data-message={caption}>
                  <img
                    className="imagem-popup"
                    src={Animad2_int}
                    alt={caption}
                    onClick={() => clickOnImage("", false)}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
