import Header from "../components/Header";
import "./EbookEua.css";
import { Link } from "react-router-dom";

import Capa from "../assets/ebook/capa4.jpg";
import Estado1 from "../assets/ebook/eua-2.jpg";
import Estado2 from "../assets/ebook/eua-3.jpg";
import Curiosidades from "../assets/ebook/eua-8.jpg";
import CompreAgoraEUR from "../assets/ebook/compreagora-euro.png";
import CompreAgoraBLR from "../assets/ebook/compreagora-reais.png";
import Reais from "../assets/ebook/reais.png";
import Euros from "../assets/ebook/euro.png";
import Voltar from "../assets/diversos/voltar.png";

export default function Ebook() {
  return (
    <>
      <Header
        cabecalho={
          "A História de Todas as Bandeiras - Edição Especial: Estados Unidos"
        }
      />

      <div className="conteudo-principal">
        <div className="paginas-desenvolvidas-eua">
          <div className="paginas-desenvolvidas-eua-texto">
            <b>
              E-book: A História de Todas as Bandeiras - Edição Especial:
              Estados Unidos
            </b>
            <br /> Nesta edição especial, vamos fundo nas histórias por trás das
            bandeiras dos estados dos Estados Unidos da América. Apresentamos
            não só dados importantes sobre cada estado, mas também curiosidades
            sobre seus brasões e as bandeiras marcantes das capitais. A
            narrativa descomplicada revela a trajetória da criação desses
            símbolos oficiais, conectando o significado por trás de cada brasão
            e bandeira. Além disso, exploramos os mapas estaduais e regionais de
            maneira simples, proporcionando uma visão completa. Não perca ainda
            fatos e curiosidades interessantes sobre vários estados americanos.
            Este livro convida você para uma jornada descontraída, desvendando a
            diversidade e história dos Estados Unidos.
          </div>

          <div
            className="paginas-desenvolvidas-eua-1"
            data-message="E-book - Capa"
          >
            <img src={Capa} alt="E-book capa" />
          </div>

          <div
            className="paginas-desenvolvidas-eua-2"
            data-message="E-book - Arkansas"
          >
            <img src={Estado1} alt="E-book Arkansas" />
          </div>

          <div
            className="paginas-desenvolvidas-eua-3"
            data-message="E-book - Geórgia"
          >
            <img src={Estado2} alt="E-book Geórgia" />
          </div>

          <div
            className="paginas-desenvolvidas-eua-4"
            data-message="E-book - Curiosidades"
          >
            <img src={Curiosidades} alt="E-book Curiosidades" />
          </div>

          <div className="paginas-desenvolvidas-eua-logoautores">
            <img src={Euros} />
            <a
              href="https://clubedeautores.pt/book/628537--A_Historia_de_Todas_as_Bandeiras"
              target="_blank"
            >
              <img
                alt="Compre aqui o livro 'A História de Todas as Bandeiras'"
                src={CompreAgoraEUR}
              />
            </a>
            <img src={Reais} />
            <a
              href="https://clubedeautores.com.br/book/628543--A_Historia_de_Todas_as_Bandeiras"
              target="_blank"
            >
              <img
                alt="Compre aqui o livro 'A História de Todas as Bandeiras'"
                src={CompreAgoraBLR}
              />
            </a>
            <Link to="/ebook">
              <img src={Voltar} alt="Voltar" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
