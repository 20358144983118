import Header from "../components/Header";
import "./EbookPortugal.css";
import { Link } from "react-router-dom";

import Capa from "../assets/ebook/capa6.jpg";
import Estado1 from "../assets/ebook/pt-2.jpg";
import Estado2 from "../assets/ebook/pt-3.jpg";
import Curiosidades from "../assets/ebook/pt-8.jpg";
import CompreAgoraEUR from "../assets/ebook/compreagora-euro.png";
import CompreAgoraBLR from "../assets/ebook/compreagora-reais.png";
import Reais from "../assets/ebook/reais.png";
import Euros from "../assets/ebook/euro.png";
import Voltar from "../assets/diversos/voltar.png";


export default function Ebook() {
  return (
    <>
      <Header
        cabecalho={"A História de Todas as Bandeiras - Edição Especial: Portugal"}
      />

      <div className="conteudo-principal">
        <div className="paginas-desenvolvidas-pt">
          <div className="paginas-desenvolvidas-pt-texto">
            <b>
              E-book: A História de Todas as Bandeiras - Edição Especial: Portugal
            </b>
            <br /> Nesta edição especial, vamos fundo nas histórias por trás das insígnias de cada distrito e território português. Apresentamos não só dados importantes sobre cada distrito, mas também curiosidades sobre seus brasões e as bandeiras marcantes das capitais. A narrativa descomplicada revela a trajetória da criação desses símbolos oficiais, conectando o significado por trás de cada brasão e bandeira. Além disso, exploramos os mapas provinciais de maneira simples, proporcionando uma visão completa. Não perca ainda fatos e curiosidades interessantes sobre os distritos português. Este livro convida você para uma jornada descontraída, desvendando a diversidade e história do país.
          </div>

          <div
            className="paginas-desenvolvidas-pt-1"
            data-message="E-book - Capa"
          >
            <img src={Capa} alt="E-book capa" />
          </div>

          <div
            className="paginas-desenvolvidas-pt-2"
            data-message="E-book - Distrito de Bragança"
          >
            <img src={Estado1} alt="E-book Distrito de Bragança" />
          </div>

          <div
            className="paginas-desenvolvidas-pt-3"
            data-message="E-book - Distrito de Leiria"
          >
            <img src={Estado2} alt="E-book Distrito de Leiria" />
          </div>

          <div
            className="paginas-desenvolvidas-pt-4"
            data-message="E-book - Curiosidades"
          >
            <img src={Curiosidades} alt="E-book Curiosidades" />
          </div>

          <div className="paginas-desenvolvidas-pt-logoautores">
            <img src={Euros} />
            <a href="https://clubedeautores.pt/book/657232--A_Historia_de_Todas_as_Bandeiras"><img alt="Compre aqui o livro 'A História de Todas as Bandeiras'" src={CompreAgoraEUR} /></a>
            <img src={Reais} />
            <a href="https://clubedeautores.com.br/book/657238--A_Historia_de_Todas_as_Bandeiras"><img alt="Compre aqui o livro 'A História de Todas as Bandeiras'" src={CompreAgoraBLR} /></a>
            <Link to="/ebook">
              <img src={Voltar} alt="Voltar" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
