import Header from "../components/Header";
import "./Sites.css";
import Imagens from "../components/Imagens";

import paises from "../assets/sites/paises.jpg";
import precos from "../assets/sites/precos.jpg";
import ceps from "../assets/sites/ceps.jpg";
import quiz from "../assets/sites/quiz.jpg";

export default function Sites() {
  return (
    <>
      <Header cabecalho={"SITES"} />
      <div className="conteudo-principal">
        <div className="sites-desenvolvidos">
          <Imagens
            classe={"sites-desenvolvidos-item"}
            imagem={paises}
            descritivo={"Informações diversas sobre países e territórios"}
            descricao={""}
            link={"https://informacao-paises.vercel.app/"}
          />
          <div className="sites-desenvolvidos-item">
            <p className="texto-site">
              <p className="titulo-texto-site">Informações dos Países</p>
              Site desenvolvido em ReactJs, utilizando JavaScrit, HTML e CSS.
              Este foi o primeiro site criado para aprimorar meus estudos de
              React, neste projeto é possivel pesqusiar os países por nome,
              parte do nome, pesquisar por nome e por parte do nome também da
              capital, filtrar somente países reconhecidos pela ONU ou não, bem
              como filtrar por continente ou por idioma. Existe ainda a
              possibilidade de classificar os países e/ou territórios filtrados
              por área ou pela população. Inicialmente, o site apresenta um grid
              com o card de todos os países e a partir dos filtros selecionados,
              os cards vão sendo ordenados para atender aos dados filtrados. O
              card traz algumas informações importantes sobre a nação como a sua
              capital, o continente, a região a qual faz parte e o seu código de
              reconhecimento internacional. Ao clicar em um dos cards, um pop up
              é aberto com informações mais detalhadas da nação selecionada,
              tais como: idioma, moeda, time zones, outras nações com as quais
              esta faz fronteira e muito mais. Caso queira visitar o site, basta
              clicar na imagem ao lado que uma nova página será aberta em uma
              nova aba do seu browser. Todas as informações foram consumidas do
              site RestCountries que nos disponibiliza uma API que nos trás
              essas informações bastante detalhadas.
            </p>
          </div>

          <Imagens
            classe={"sites-desenvolvidos-item"}
            imagem={quiz}
            descritivo={"Descubra a bandeira de diversas nações"}
            descricao={""}
            link={"https://quiz-paises.vercel.app/"}
          />
          <div className="sites-desenvolvidos-item">
            <p className="texto-site">
              <p className="titulo-texto-site">Quiz Países</p>
              Site desenvolvido em ReactJs, utilizando JavaScrit, HTML e CSS. É
              um jogo interativo de adivinhação de bandeiras. O sistema mostra
              ao jogador 4 bandeiras aleatórias dos 250 países ou territórios
              reconhecidos reconhecidos pela ONU ou não. O usuário deve clicar
              na bandeira que ele pensa ser a correta referente ao nome do país
              informado logo acima do quadro com as 4 opções. O usuário pode
              pular 3 vezes caso não saiba ou tenha dúvidas sobre a resposta.
              Também é possível configurar o jogo para mostrar banderias de um
              determinado continente e também escolher entre nações reconhecidas
              ou não. É possível alterar a quantidade de rodadas a serem
              disputadas, sempre obedecendo a quantidade máxima de banderias da
              seleção (caso indique uma quantidade de rodadas que exceda a
              quantidade de nações do filtro, este é alterado automaticamente
              para a quantidade máxima possível). O jogador tem 20 segundos para
              tentar adivinhar a bandeira, caso o tempo se esgote, é contado
              como erro. Ao final das rodadas será apresentado o resultado
              percentual de acertos.
            </p>
          </div>

          <Imagens
            classe={"sites-desenvolvidos-item"}
            imagem={precos}
            descritivo={"Comparativos de preços entre R$ e €"}
            descricao={""}
            link={"https://comparativo-precos.vercel.app/"}
          />
          <div className="sites-desenvolvidos-item">
            <p className="texto-site">
              <p className="titulo-texto-site">
                Comparativos de preços entre R$ e €
              </p>
              No intuito de continuar aprimorando meu estudo de ReactJS,
              desenvolvi esse site para que os brasileiros que vivem em Portugal
              possam ter uma noção dos valores dos produtos relativos ao salário
              mínimo de ambos os países. Ao inserir o valor do produto em reais
              e em euros, o sistema calcula o valor percentual em relação ao
              salário mínimo dos países e mostra também o dias de trabalhos
              necessários para adiquirir este produto. É possível ver ainda como
              resultado o poder de compra de cada moeda onde é indicado a
              quantidade de produtos que é possível comprar com o salário mínimo
              indicado. É uma boa estratégia para se situar em relação a
              diferença de preços sem precisar ficar fazendo conversão direta,
              que na prática não é a melhor das alternativas para fazer esta
              análise.
            </p>
          </div>

          <Imagens
            classe={"sites-desenvolvidos-item"}
            imagem={ceps}
            descritivo={
              "Consulta de CEPs na base de dados dos Correios do Brasil"
            }
            descricao={""}
            link={"https://consulta-ceps.vercel.app/"}
          />
          <div className="sites-desenvolvidos-item">
            <p className="texto-site">
              <p className="titulo-texto-site">
                Consulta de CEPs na base de dados dos Correios do Brasil
              </p>
              Ainda na pegada para aprimorar minhas skills em ReactJS,
              desenvolvi esse site para consultar o CEP de qualquer município
              dos estados brasileiros, podendo consultar por nome do logradouro
              (ou parte dele) e/ou consultar pelo próprio CEP para obter o
              logradouro. O site consome os dados da API gratuita dos correios
              VIACEP. Para não ficar uma busca extremamente abrangente, é
              necessário escolher o estado e a cidade de onde se deseja
              consultar. Ao selecionar o estado, o combo dos municípios é
              preenchido automaticamente com as cidades do estado anteriormente
              selecionado.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
