import SocialMedia from "../components/SocialMedia";
import logo from "../assets/diversos/logo.png";
import "./Home.css";
import Temperaturas from "../components/Temperaturas";

function dataAtual() {
  let dataAtual = new Date();
  const diasSemana = [
    "Domingo",
    "Segunda-feira",
    "Terça-feira",
    "Quarta-feira",
    "Quinta-feira",
    "Sexta-feira",
    "Sábado",
  ];
  const mes = [
    "janeiro",
    "fevereiro",
    "março",
    "abril",
    "maio",
    "junho",
    "julho",
    "agosto",
    "setembro",
    "outubro",
    "novembro",
    "dezembro",
  ];

  const dia = dataAtual.getDate();
  const diaSemana = diasSemana[dataAtual.getDay()];
  const mesAtual = mes[dataAtual.getMonth()];
  const ano = dataAtual.getFullYear();

  const dataFormatada =
    diaSemana + ", " + dia + " de " + mesAtual + " de " + ano;

  return dataFormatada;
}

export default function Home() {
  return (
    <div className="conteudo-principal">
      <div className="data-atual">{dataAtual()}</div>
      <div className="logo">
        <img src={logo} alt="Frederico Zanitti Silva" />
      </div>
      <div className="temperaturas">
        <Temperaturas />
      </div>
      <div className="social-media">
        <SocialMedia />
      </div>
    </div>
  );
}
