import axios from "axios";

export function BuscaClimaTempo(lat, lon) {
  const appId = "230f3ba624b912289a1b30767c153fec";
  const language = "pt_br";

  let url = `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=${appId}&lang=${language}&units=metric`;

  return axios.get(url);
}
