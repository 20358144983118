import Header from "../components/Header";
import "./Softwares.css";
import campanha from "../assets/softwares/softcampanha.jpg";
import copa10 from "../assets/softwares/softcopa.jpg";
import copa14 from "../assets/softwares/softcopa14.jpg";
import distintivos from "../assets/softwares/softdistintivos.jpg";
import escola from "../assets/softwares/softescolar.jpg";
import mestreCuca from "../assets/softwares/softmestrecuca.jpg";
import quiz from "../assets/softwares/softquiz.jpg";

import Imagens from "../components/Imagens";

export default function Softwares() {
  function textoSoftwares(id) {
    switch (id) {
      case 1: //campanha ano a ano
        return (
          <p className="texto-popup">
            <p className="titulo-popup">CAMPANHA ANO A ANO</p> A idealização
            deste software foi inspirada na minha vontade de reunir em um único
            ambiente todos os dados estatísticos referente ao meu Time do
            Coração. Meu apreço pelo futebol, mais especificamente pelo
            Fluminene F.C., aliado a minha vontade, fez com que eu desse início
            ao projeto que levou cerca de cinco meses para ser concluído. O
            software foi desenvolvido na mais nova versão do Delphi (XE7) e
            utiliza um banco de dados seguro, estável e confiável (MySQL). Seu
            gerenciamento consiste basicamente em quatro etapas: cadastro do Seu
            Time do Coração; cadastro dos jogadores de seu time; cadastro dos
            clubes adversários e cadastro dos jogos, necessariamente nesta
            ordem. Vale destacar que o software permite o gerenciamento de um
            único Clube do Coração. A partir daí são inúmeros relatórios
            estatísticos disponíveis para você acompanhar a trajetória de seu
            clube na temporada. Torcedores mais fanáticos (como eu) certamente
            vão cadastrar todos os confrontos do seu clube desde sua fundação.
            Quer saber quantas partidas o seu time disputou contra o seu rival?
            Qual jogador encabeça a lista de artilheiros do seu clube? Quem mais
            treinou o seu time? Quem mais jogou? Quem tomou mais cartões na
            história? Este software responde a todas essas perguntas e muito
            mais.
          </p>
        );
      case 2: //copa 2010
        return (
          <p className="texto-popup">
            <p className="titulo-popup">
              COPA DO MUNDO FIFA 2010 - ÁFRICA DO SUL
            </p>
            Como admirador do bom futebol que sou, esse era um desafio que me
            propuz a encarar já há algum tempo, e nada como um evento do porte
            de uma Copa do Mundo para colocar em prática este desafio. Por fim,
            seu desenvolvimento não foi tão complexo como imaginava. A tabela é
            simples, basta sair do modo de edição da célula para que o resultado
            fique gravado e as posições no grupo sejam reordenadas. As seleções
            classificadas só aparecem na fase de mata-mata quando o grupo
            estiver fechado, ou seja, quando todas as seleções tiverem jogados
            todos os seus jogos. Foi gratificante, valeu a pena! O software foi
            desenvolvido em Delphi 7 e utiliza o Firebird como banco de dados.
          </p>
        );
      case 3: //copa 2014
        return (
          <p className="texto-popup">
            <p className="titulo-popup">COPA DO MUNDO FIFA - BRASIL 2014</p>Um
            evento grandioso como uma Copa do Mundo não poderia passar
            despercebido. Aliando minha paixão pelo futebol, disponibilizo o
            download da nova Tabela Eletrônica da Copa do Mundo 2014. O processo
            segue exatamente como o desenvolvido para a tabela da copa passada,
            também disponibilizado neste espaço, ou seja, basta inserir o
            resultado do jogo para que a classificação seja atualizada
            automaticamente e no fechamento do grupo as seleções classficidadas
            serão pareadas em seus respectivos confrontos nas oitavas de final.
            Vamos torcer pelo Brasil, buscar o hexa e agora, com o software,
            acompanhar a tragetória da conquista. O software foi desenvolvido em
            Delphi 7 e utiliza o Firebird como banco de dados.
          </p>
        );
      case 4: //distintivos
        return (
          <p className="texto-popup">
            <p className="titulo-popup">CADASTRO COLEÇÃO DE DISTINTIVOS</p>Este
            software foi ideia de um parceiro apaixonado por logomarcas de
            clubes de futebol de todo o mundo. Nos conhecemos através da
            parceria com o site www.soccerlogos.com.br onde enviávamos
            logomarcas vetorizadas para abastecer o site com escudos bem
            desenhados e em ótima qualidade. Daí surgiu a ideia de ter um
            software para cadastro dessas logomarcas juntamente com outras
            informações pertinentes aos clubes, tais como: estádio, nome
            completo, data de fundação, uniforme dentre outros. O software foi
            desenvolvido em Delphi 7 e utiliza o banco de dados Paradox.
          </p>
        );
      case 5: //escola
        return (
          <p className="texto-popup">
            <p className="titulo-popup">
              SISTEMA GERENCIADOR DE CADASTRO ESCOLAR
            </p>
            Software desenvolvido para cadastros básicos de informações de
            alunos, professores e funcionários. O software foi desenvolvido para
            atender a demanda do estágio obrigatório cursado no 7º período do
            curso de Sistema da Informação. O software contempla cadastro de
            aluno, pais, responsáveis, matrículas, turmas, notas e integra com o
            sistema de cobrança da Caixa Econômica Federal para emissão de
            boletos. Foi desenvolvido em Delphi 7 e utiliza banco de dados
            Firebird 2.5.
          </p>
        );
      case 6: //quiz
        return (
          <p className="texto-popup">
            <p className="titulo-popup">L3TR45 & NÚM3R05 - DESAFIOS DIÁRIOS</p>
            Este software foi desenvolvido para ser distribuído aos alunos do
            Ensino Fundamental do Colégio Efigênia Vidigal na Mostra Cultural
            (evento anual do colégio). A ideia foi de um grupo de professoras
            com o intuito de reunir questões das disciplinas dos cursos do
            Ensino Fundamental juntamente com questões de conhecimentos gerais e
            de informática. O software foi um sucesso! No melhor estilo Quiz, as
            perguntas são aleatórias e a pessoa deve responder um total de 20
            perguntas, com direito a dois pulos. No fim é apresentada a sua
            pontuação final. O software armazena os resultados e na tela inicial
            é apresentado o resultado da pessoa com melhor pontuação. O software
            foi desenvolvido em Delphi 7 e utiliza o banco de dados Paradox. Há
            possibilidade do usuário cadastrar novas perguntas no banco.
          </p>
        );
      case 7: //mestrecuca
        return (
          <p className="texto-popup">
            <p className="titulo-popup">MESTRE CUCA ELETRÔNICO</p>Este software
            foi um pedido pessoal de um amigo, Chefe de Cozinha. Cansado de
            recorrer a receitas em diversas fontes, cansado de perder tempo
            pesquisando em inúmeros livros e cadernos, solicitou-me o
            desenvolvimento deste que o atendeu eficazmente. Uma vez cadastrada
            a receita, seu acesso é rápido e fácil. A pesquisa demora segundos,
            agilizando seus trabalhos e lhe dando mais tempo para preparar
            pratos mais saborosos. O software foi desenvolvido em Delphi 7. O
            banco de dados utilizado foi o já difundido e confiável Firebird.
          </p>
        );
      default:
        break;
    }
  }

  return (
    <>
      <Header cabecalho={"SOFTWARES"} />
      <div className="conteudo-principal">
        <div className="softwares-desenvolvidos">
          <Imagens
            classe={"softwares-desenvolvidos-item"}
            imagem={campanha}
            descritivo={"Campanha Ano a Ano"}
            descricao={textoSoftwares(1)}
          />

          <Imagens
            classe={"softwares-desenvolvidos-item"}
            imagem={copa10}
            descritivo={"Copa do Mundo FIFA - África do Sul 2010"}
            descricao={textoSoftwares(2)}
          />

          <Imagens
            classe={"softwares-desenvolvidos-item"}
            imagem={copa14}
            descritivo={"Copa do Mundo FIFA - Brasil 2014"}
            descricao={textoSoftwares(3)}
          />

          <Imagens
            classe={"softwares-desenvolvidos-item"}
            imagem={distintivos}
            descritivo={"Cadastro de Distintivos de Clubes do Mundo"}
            descricao={textoSoftwares(4)}
          />

          <Imagens
            classe={"softwares-desenvolvidos-item"}
            imagem={escola}
            descritivo={"Sistema Gerenciador de Cadastro Escolar"}
            descricao={textoSoftwares(5)}
          />

          <Imagens
            classe={"softwares-desenvolvidos-item"}
            imagem={quiz}
            descritivo={"L3tr45 & Núm3r05 - Desafios Diários"}
            descricao={textoSoftwares(6)}
          />

          <Imagens
            classe={"softwares-desenvolvidos-item"}
            imagem={mestreCuca}
            descritivo={"Mestre Cuca Eletrônico"}
            descricao={textoSoftwares(7)}
          />
        </div>
      </div>
    </>
  );
}
