import Header from "../components/Header";
import "./EbookBrasil.css";
import { Link } from "react-router-dom";

import Capa from "../assets/ebook/capa3.jpg";
import Estado1 from "../assets/ebook/br-2.jpg";
import Estado2 from "../assets/ebook/br-3.jpg";
import Curiosidades from "../assets/ebook/br-8.jpg";
import CompreAgoraEUR from "../assets/ebook/compreagora-euro.png";
import CompreAgoraBLR from "../assets/ebook/compreagora-reais.png";
import Reais from "../assets/ebook/reais.png";
import Euros from "../assets/ebook/euro.png";
import Voltar from "../assets/diversos/voltar.png";

export default function Ebook() {
  return (
    <>
      <Header
        cabecalho={"A História de Todas as Bandeiras - Edição Especial: Brasil"}
      />

      <div className="conteudo-principal">
        <div className="paginas-desenvolvidas-br">
          <div className="paginas-desenvolvidas-br-texto">
            <b>
              E-book: A História de Todas as Bandeiras - Edição Especial: Brasil
            </b>
            <br /> Nesta edição especial, vamos fundo nas histórias por trás das
            bandeiras das unidades federativas brasileiras. Apresentamos não só
            dados importantes sobre cada estado, mas também curiosidades sobre
            seus brasões e as bandeiras marcantes das capitais. A narrativa
            descomplicada revela a trajetória da criação desses símbolos
            oficiais, conectando o significado por trás de cada brasão e
            bandeira. Além disso, exploramos os mapas estaduais e regionais de
            maneira simples, proporcionando uma visão completa. Não perca ainda
            fatos e curiosidades interessantes sobre vários estados do Brasil.
            Este livro convida você para uma jornada descontraída, desvendando a
            diversidade e história do nosso país.
          </div>

          <div
            className="paginas-desenvolvidas-br-1"
            data-message="E-book - Capa"
          >
            <img src={Capa} alt="E-book capa" />
          </div>

          <div
            className="paginas-desenvolvidas-br-2"
            data-message="E-book - ( DF ) Distrito Federal"
          >
            <img src={Estado1} alt="E-book DF-Distrito Federal" />
          </div>

          <div
            className="paginas-desenvolvidas-br-3"
            data-message="E-book - ( MA ) Maranhão"
          >
            <img src={Estado2} alt="E-book MA-Maranhão" />
          </div>

          <div
            className="paginas-desenvolvidas-br-4"
            data-message="E-book - Curiosidades"
          >
            <img src={Curiosidades} alt="E-book Curiosidades" />
          </div>

          <div className="paginas-desenvolvidas-br-logoautores">
            <img src={Euros} />
            <a
              href="https://clubedeautores.pt/book/624453--A_Historia_de_Todas_as_Bandeiras"
              target="_blank"
            >
              <img
                alt="Compre aqui o livro 'A História de Todas as Bandeiras do Mundo'"
                src={CompreAgoraEUR}
              />
            </a>
            <img src={Reais} />
            <a
              href="https://clubedeautores.com.br/book/624457--A_HIstoria_de_Todas_as_Bandeiras"
              target="_blank"
            >
              <img
                alt="Compre aqui o livro 'A História de Todas as Bandeiras do Mundo'"
                src={CompreAgoraBLR}
              />
            </a>
            <Link to="/ebook">
              <img src={Voltar} alt="Voltar" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
