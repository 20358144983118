import "./App.css";

import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Menu from "./components/Menu";
import { useEffect } from "react";

import Home from "./pages/Home";
import Agendas from "./pages/Agendas";
import Academicos from "./pages/Academicos";
import DesenhoLivre from "./pages/DesenhoLivre";
import Contato from "./pages/Contato";
import Logomarcas from "./pages/Logomarcas";
import Profile from "./pages/Profile";
import Softwares from "./pages/Softwares";
import Sites from "./pages/Sites";
import VetorizacaoDesenho from "./pages/VetorizacaoDesenho";
import Ebook from "./pages/Ebook";
import EbookMundo from "./pages/EbookMundo";
import EbookBrasil from "./pages/EbookBrasil";
import EbookEua from "./pages/EbookEua";
import EbookCanada from "./pages/EbookCanada";
import EbookPortugal from "./pages/EbookPortugal";

function App() {
  useEffect(() => {
    function getWindowDimensions() {
      let { innerWidth: width, innerHeight: height } = window;
      const isXSmall = width < 600;
      const isSmall = width >= 600 && width < 960;
      const isMedium = width >= 960 && width < 1280;
      const isLarge = width >= 1280;
      width = width - 1;
      height = height - 1;
      return {
        width,
        height,
        isXSmall,
        isSmall,
        isMedium,
        isLarge,
      };
    }

    function handleResize() {
      const windowDimensions = getWindowDimensions();
      console.log(windowDimensions);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Menu />
        <div className="conteudo-principal">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/academico" element={<Academicos />} />
            <Route path="/agenda" element={<Agendas />} />
            <Route path="/desenholivre" element={<DesenhoLivre />} />
            <Route path="/contato" element={<Contato />} />
            <Route path="/logomarca" element={<Logomarcas />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/software" element={<Softwares />} />
            <Route path="/sites" element={<Sites />} />
            <Route path="/vetdesenho" element={<VetorizacaoDesenho />} />
            <Route path="/ebook" element={<Ebook />} />
            <Route path="/ebookMundo" element={<EbookMundo />} />
            <Route path="/ebookBrasil" element={<EbookBrasil />} />
            <Route path="/ebookEua" element={<EbookEua />} />
            <Route path="/ebookCanada" element={<EbookCanada />} />
            <Route path="/ebookPortugal" element={<EbookPortugal />} />

            {/* Outras rotas */}
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
