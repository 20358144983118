import Header from "../components/Header";
import "./EbookMundo.css";
import { Link } from "react-router-dom";

import Capa from "../assets/ebook/capa1.jpg";
import Africa from "../assets/ebook/africa2.jpg";
import AmericaSul from "../assets/ebook/americadosul2.jpg";
import AmericaNorte from "../assets/ebook/americadonorte2.jpg";
import Asia from "../assets/ebook/asia2.jpg";
import Europa from "../assets/ebook/europa2.jpg";
import Oceania from "../assets/ebook/oceania2.jpg";
import Curiosidades from "../assets/ebook/curiosidades2.jpg";
import CompreAgoraEUR from "../assets/ebook/compreagora-euro.png";
import CompreAgoraBLR from "../assets/ebook/compreagora-reais.png";
import Reais from "../assets/ebook/reais.png";
import Euros from "../assets/ebook/euro.png";
import Voltar from "../assets/diversos/voltar.png";

export default function Ebook() {
  return (
    <>
      <Header cabecalho={"A História de Todas as Bandeiras do Mundo"} />

      <div className="conteudo-principal">
        <div className="paginas-desenvolvidas">
          <div className="paginas-desenvolvidas-texto">
            <b>E-book: A História de Todas as Bandeiras do Mundo</b> <br />{" "}
            Explore a fascinante narrativa por trás de todas as bandeiras do
            mundo, abrangendo desde as nações reconhecidas pela ONU até
            territórios e estados não oficialmente reconhecidos. Esta fonte rica
            em informações oferece reflexões históricas sobre a origem das
            bandeiras, além de fornecer dados detalhados sobre cada país, como
            capital, moeda, área, entre outros. Deixe-se surpreender por
            curiosidades intrigantes sobre as diferentes nações e seus símbolos,
            e reviva momentos marcantes, como a dissolução da URSS e o
            surgimento de novas nações a partir desse evento.
          </div>

          <div className="paginas-desenvolvidas-1" data-message="E-book - Capa">
            <img src={Capa} alt="E-book capa" />
          </div>

          <div
            className="paginas-desenvolvidas-2"
            data-message="E-book - África do Sul"
          >
            <img src={Africa} alt="E-book África do Sul" />
          </div>

          <div
            className="paginas-desenvolvidas-3"
            data-message="E-book - Brasil"
          >
            <img src={AmericaSul} alt="E-book Brasil" />
          </div>

          <div
            className="paginas-desenvolvidas-4"
            data-message="E-book - Estados Unidos"
          >
            <img src={AmericaNorte} alt="E-book Estados Unidos" />
          </div>

          <div
            className="paginas-desenvolvidas-1"
            data-message="E-book - Coréia do Sul"
          >
            <img src={Asia} alt="E-book Coréia do Sul" />
          </div>

          <div
            className="paginas-desenvolvidas-2"
            data-message="E-book - Portugal"
          >
            <img src={Europa} alt="E-book Portugal" />
          </div>

          <div
            className="paginas-desenvolvidas-3"
            data-message="E-book - Austrália"
          >
            <img src={Oceania} alt="E-book Austrália" />
          </div>

          <div
            className="paginas-desenvolvidas-4"
            data-message="E-book - Curiosidades"
          >
            <img src={Curiosidades} alt="E-book Curiosidades" />
          </div>

          <div className="paginas-desenvolvidas-logoautores">
            <img src={Euros} />
            <a
              href="https://clubedeautores.pt/book/614364--A_Historia_de_Todas_as_Bandeiras_do_Mundo"
              target="_blank"
            >
              <img
                alt="Compre aqui o livro 'A História de Todas as Bandeiras do Mundo'"
                src={CompreAgoraEUR}
              />
            </a>
            <img src={Reais} />
            <a
              href="https://clubedeautores.com.br/book/616554--A_Historia_de_Todas_as_Bandeiras_do_Mundo"
              target="_blank"
            >
              <img
                alt="Compre aqui o livro 'A História de Todas as Bandeiras do Mundo'"
                src={CompreAgoraBLR}
              />
            </a>
            <Link to="/ebook">
              <img src={Voltar} />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
