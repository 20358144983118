import axios from "axios";

export function ConvertCityLatLong(city, country) {
  const appId = "230f3ba624b912289a1b30767c153fec";
  const url = `https://api.openweathermap.org/data/2.5/weather?q=${city},${country}&APPID=${appId}&units=metric`;

  return axios
    .get(url)
    .then((response) => {
      const { lat, lon } = response.data.coord;
      return { lat, lon };
    })
    .catch((error) => {
      throw error;
    });
}
