import "./Agendas.css";
import Header from "../components/Header";

import Bek2017 from "..//assets/agendas/bek2017.jpg";
import Bek2018 from "..//assets/agendas/bek2018.png";
import Bek2019 from "..//assets/agendas/bek2019.jpg";
import EvFundI from "..//assets/agendas/evfundI.jpg";
import EvFundII from "..//assets/agendas/evfundII.jpg";
import EvInf from "..//assets/agendas/evinf.jpg";
import ModBer2013 from "..//assets/agendas/mod-ber2013.jpg";
import ModBer2014 from "..//assets/agendas/mod-ber2014.jpg";
import ModInf2013 from "..//assets/agendas/mod-inf2013.jpg";
import ModInf2014 from "..//assets/agendas/mod-inf2014.jpg";

export default function Agendas() {
  return (
    <>
      <Header cabecalho={"AGENDAS"} />
      <div className="conteudo-principal">
        <div className="agendas-confeccionadas">
          <div className="agendas-confeccionadas-titulo-1">
            Buritis Espaço Kids (2017 a 2019)
          </div>
          <a
            className="agendas-confeccionadas-11"
            href="http://online.fliphtml5.com/ocdo/vahc/"
            target="_blank"
            rel="noreferrer"
            data-message="Buritis Espaço Kids, agenda 2017 | Belo Hoizonte/MG"
          >
            <img
              src={Bek2017}
              alt="Buritis Espaço Kids, agenda 2017 | Belo Hoizonte/MG"
            />
          </a>
          <a
            className="agendas-confeccionadas-21"
            href="http://online.fliphtml5.com/ocdo/kugp/"
            target="_blank"
            rel="noreferrer"
            data-message="Buritis Espaço Kids, agenda 2018 | Belo Hoizonte/MG"
          >
            <img
              src={Bek2018}
              alt="Buritis Espaço Kids, agenda 2018 | Belo Hoizonte/MG"
            />
          </a>

          <a
            className="agendas-confeccionadas-31"
            href="http://online.fliphtml5.com/ocdo/iwke/"
            target="_blank"
            rel="noreferrer"
            data-message="Buritis Espaço Kids, agenda 2019 | Belo Hoizonte/MG"
          >
            <img
              src={Bek2019}
              alt="Buritis Espaço Kids, agenda 2019 | Belo Hoizonte/MG"
            />
          </a>

          <div className="agendas-confeccionadas-titulo-2">
            Colégio Efigênia Vidigal (2009)
          </div>
          <a
            className="agendas-confeccionadas-12"
            href="https://online.fliphtml5.com/ocdo/pwlf/"
            target="_blank"
            rel="noreferrer"
            data-message="Colégio Efigênia Vidigal, agenda Infantil 2009 | Belo Hoizonte/MG"
          >
            <img
              src={EvInf}
              alt="Colégio Efigênia Vidigal, agenda Infantil 2009 | Belo Hoizonte/MG"
            />
          </a>

          <a
            className="agendas-confeccionadas-22"
            href="http://online.fliphtml5.com/ocdo/eiur/"
            target="_blank"
            rel="noreferrer"
            data-message="Colégio Efigênia Vidigal, agenda Fundamental I 2009 | Belo Hoizonte/MG"
          >
            <img
              src={EvFundI}
              alt="Colégio Efigênia Vidigal, agenda Fundamental I 2009 | Belo Hoizonte/MG"
            />
          </a>

          <a
            className="agendas-confeccionadas-32"
            href="http://online.fliphtml5.com/ocdo/tomt/"
            target="_blank"
            rel="noreferrer"
            data-message="Colégio Efigênia Vidigal, agenda Fundamental II 2009 | Belo Hoizonte/MG"
          >
            <img
              src={EvFundII}
              alt="Colégio Efigênia Vidigal, agenda Fundamental II 2009 | Belo Hoizonte/MG"
            />
          </a>

          <div className="agendas-confeccionadas-titulo-3">
            Escola Modelo (2013 e 2014)
          </div>
          <a
            className="agendas-confeccionadas-13"
            href="https://online.fliphtml5.com/ocdo/wuor/"
            target="_blank"
            rel="noreferrer"
            data-message="Escola Modello, agenda Berçário 2013 | Belo Hoizonte/MG"
          >
            <img
              src={ModBer2013}
              alt="Escola Modello, agenda Berçário 2013 | Belo Hoizonte/MG"
            />
          </a>

          <a
            className="agendas-confeccionadas-23"
            href="https://online.fliphtml5.com/ocdo/xrrl/"
            target="_blank"
            rel="noreferrer"
            data-message="Escola Modello, agenda Infantil 2013 | Belo Hoizonte/MG"
          >
            <img
              src={ModBer2014}
              alt="Escola Modello, agenda Infantil 2013 | Belo Hoizonte/MG"
            />
          </a>

          <a
            className="agendas-confeccionadas-33"
            href="https://online.fliphtml5.com/ocdo/bzmy/"
            target="_blank"
            rel="noreferrer"
            data-message="Escola Modello, agenda Berçário 2014 | Belo Hoizonte/MG"
          >
            <img
              src={ModInf2013}
              alt="Escola Modello, agenda Berçário 2014 | Belo Hoizonte/MG"
            />
          </a>

          <a
            className="agendas-confeccionadas-43"
            href="https://online.fliphtml5.com/ocdo/ttby/"
            target="_blank"
            rel="noreferrer"
            data-message="Escola Modello, agenda Infantil 2014 | Belo Hoizonte/MG"
          >
            <img
              src={ModInf2014}
              alt="Escola Modello, agenda Infantil 2014 | Belo Hoizonte/MG"
            />
          </a>
        </div>
      </div>
    </>
  );
}
