import "./SocialMedia.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faInstagram,
  faFacebook,
  faGithub,
  faLinkedin,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

export default function SocialMedia() {
  return (
    <div className="icons-social-media-general">
      <a
        href="https://www.instagram.com/frederico_zanitti/"
        target="_blank"
        rel="noreferrer"
        className="icons-social-media-instagram"
      >
        <FontAwesomeIcon icon={faInstagram} />
      </a>

      <a
        href="https://www.facebook.com/frederico.zanitti"
        target="_blank"
        rel="noreferrer"
        className="icons-social-media-facebook"
      >
        <FontAwesomeIcon icon={faFacebook} />
      </a>

      <a
        href="https://br.linkedin.com/in/fredericozanitti"
        target="_blank"
        rel="noreferrer"
        className="icons-social-media-linkedin"
      >
        <FontAwesomeIcon icon={faLinkedin} />
      </a>

      <a
        href="https://github.com/FredericoZanitti"
        target="_blank"
        rel="noreferrer"
        className="icons-social-media-github"
      >
        <FontAwesomeIcon icon={faGithub} />
      </a>

      <a
        href="https://wa.me/351933651251"
        target="_blank"
        rel="noreferrer"
        className="icons-social-media-whatsapp"
      >
        <FontAwesomeIcon icon={faWhatsapp} />
      </a>
    </div>
  );
}
